.HeroContainer {
  align-items: center;
  animation: gradient 15s ease infinite;
  background: var(--sl-primary);
  background-image: 
    radial-gradient(
      farthest-corner circle at 0% 100%, 
      #00dfff 0%, #8c00ea 50%, #ff6200 100%
    );
  background-size: 200% 200%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-size: 36px;
  justify-content: center;
  text-align: center;
  width: 100vw;

  & > p {
    user-select: none;
  }
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.HeroNavList {
  color: #000;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  gap: 16px;
  list-style-type: none;
  padding: 0px;

  & > a {
    color: green;
  }
}

.NavContainer {
  position: absolute;
  top: 0px;
}

li > a{
  color: #FAF9F6;
  text-decoration: none;
}

@keyframes grow-progress {
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
}

#progress {
  position: fixed;
  left: 0; top: 0;
  width: 100%; height: 1em;
  background: red;

  transform-origin: 0 50%;
  animation: grow-progress auto linear;
  animation-timeline: scroll();
}

button {
background: rgba(255, 255, 255, 0.32);
border: none;
border-radius: 12px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(7.1px);
-webkit-backdrop-filter: blur(7.1px);
transition: 500ms;
  
  padding:  4px 8px;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    border-radius: 8px;
  }
}

.floating-action-button.nav-anchor {
  background: transparent;
  box-shadow: none;
  bottom: 25%;
  left: calc(50% - 24px);
  max-block-size: fit-content;

  &:hover {
    transition: 400ms;
    color: #eee;
  }
}

@media screen and (max-width: 650px) {
  .floating-action-button.desktop-only {
    display: none !important;
    opacity: 0;
  }
}

.floating-action-button {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.52);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  backdrop-filter: blur(7.1px);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(7.1px);
  bottom: 48px;
  right: 48px;
  height: 48px;
  width: 48px;
  position: absolute;
  z-index: 9;

  &:hover {
    transform: scale(1.05);
  }
}

.dissolveUp {
  --translate-distance: -35vh;
  transition: 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transform: scale(0.7) translateY(var(--translate-distance, 0));

  @media screen and (max-width: 700px) {
    --translate-distance: -15vh;
  }
}
.hidden {
  height: 0px;
  opacity: 0;
  transition: 1s ease-in-out;
  transform: translateY(10vh);
  transition-delay: 100ms;
}
.revealIn {
  opacity: 1;
  transform: translateY(-5vh);
}
.bodyText.caption {
  font-size: 16px;
}
.bodyText {
  height: fit-content;
  padding: 0 16px;

  @media screen and (max-width: 700px) {
    font-size: 24px;
  }
}