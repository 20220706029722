.prompt-dialog {
  -webkit-backdrop-filter: blur(7.1px);
  z-index: 8;
  background: #ffffff85;
  border-radius: 12px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 0;
  height: 0;
  transition: all .5s;
  display: flex;
  position: absolute;
  bottom: 72px;
  right: 72px;
  box-shadow: 0 4px 30px #0000001a;
}

.prompt-dialog > input {
  opacity: 0;
  background-color: #ffffffa6;
  border: 1px solid #a9a9a9;
  border-radius: 4px;
  width: 100%;
  line-height: 24px;
  transition: opacity .1s;
}

.prompt-dialog.open > input {
  opacity: 1;
}

.prompt-dialog.open {
  opacity: 1;
  width: 256px;
  height: 384px;
  padding: 32px 16px 48px;
  transition-delay: 50ms;
}

.prompt-dialog > h3 {
  opacity: 0;
  align-self: flex-start;
  margin: 0;
}

.prompt-dialog.open > h3 {
  opacity: 1;
}

.prompt-body {
  opacity: 0;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  max-height: 256px;
  display: flex;
  overflow: auto;
}

.open .prompt-body {
  opacity: 1;
}

.msg-entry {
  grid-template-columns: 24px 1fr;
  gap: 12px;
  width: 100%;
  display: grid;
}

.msg-entry .icon {
  backdrop-filter: blur(7.1px);
  background: #0e7cff85;
  border-radius: 50%;
  justify-self: center;
  width: 12px;
  height: 12px;
  margin-top: 6px;
  box-shadow: 0 4px 30px #0000001a;
}

.HeroContainer {
  background: var(--sl-primary);
  text-align: center;
  background-image: radial-gradient(circle at 0 100%, #00dfff 0%, #8c00ea 50%, #ff6200 100%);
  background-size: 200% 200%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  font-size: 36px;
  animation: 15s infinite gradient;
  display: flex;
}

.HeroContainer > p {
  -webkit-user-select: none;
  user-select: none;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.HeroNavList {
  color: #000;
  flex-direction: row;
  gap: 16px;
  padding: 0;
  font-size: 16px;
  list-style-type: none;
  display: flex;
}

.HeroNavList > a {
  color: green;
}

.NavContainer {
  position: absolute;
  top: 0;
}

li > a {
  color: #faf9f6;
  text-decoration: none;
}

@keyframes grow-progress {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

#progress {
  transform-origin: 0;
  animation: grow-progress auto linear;
  animation-timeline: scroll();
  background: red;
  width: 100%;
  height: 1em;
  position: fixed;
  top: 0;
  left: 0;
}

button {
  -webkit-backdrop-filter: blur(7.1px);
  cursor: pointer;
  background: #ffffff52;
  border: none;
  border-radius: 12px;
  padding: 4px 8px;
  transition: all .5s;
  box-shadow: 0 4px 30px #0000001a;
}

button:hover {
  border-radius: 8px;
  transform: scale(1.05);
}

.floating-action-button.nav-anchor {
  box-shadow: none;
  background: none;
  max-block-size: fit-content;
  bottom: 25%;
  left: calc(50% - 24px);
}

.floating-action-button.nav-anchor:hover {
  color: #eee;
  transition: all .4s;
}

@media screen and (width <= 650px) {
  .floating-action-button.desktop-only {
    opacity: 0;
    display: none !important;
  }
}

.floating-action-button {
  cursor: pointer;
  -webkit-backdrop-filter: blur(7.1px);
  z-index: 9;
  background: #ffffff85;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  display: flex;
  position: absolute;
  bottom: 48px;
  right: 48px;
  box-shadow: 0 4px 30px #0000001a;
}

.floating-action-button:hover {
  transform: scale(1.05);
}

.dissolveUp {
  --translate-distance: -35vh;
  transform: scale(.7) translateY(var(--translate-distance, 0));
  transition: all 2s cubic-bezier(.445, .05, .55, .95);
}

@media screen and (width <= 700px) {
  .dissolveUp {
    --translate-distance: -15vh;
  }
}

.hidden {
  opacity: 0;
  height: 0;
  transition: all 1s ease-in-out .1s;
  transform: translateY(10vh);
}

.revealIn {
  opacity: 1;
  transform: translateY(-5vh);
}

.bodyText.caption {
  font-size: 16px;
}

.bodyText {
  height: fit-content;
  padding: 0 16px;
}

@media screen and (width <= 700px) {
  .bodyText {
    font-size: 24px;
  }
}

body {
  height: 100%;
  overflow-y: auto;
}

.resume {
  padding: 48px 64px;
}

.role {
  background: linear-gradient(#f7f9fd, #0000);
  border-radius: 16px;
  margin: 16px 0;
  padding: 16px 24px;
}

.skills {
  flex-flow: wrap;
  gap: 8px;
  margin-top: 16px;
  font-size: 12px;
  display: flex;
}

.chip {
  background-color: #00dfff;
  border-radius: 24px;
  max-block-size: fit-content;
  padding: 8px 12px;
}

a {
  color: #faf9f6;
  text-decoration: none;
  transition: all .1s ease-in-out;
}

a:hover {
  color: #faf9f6;
  transform: scale(1.05);
}

.llamaHeader {
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
  display: flex;
  position: absolute;
  top: 24px;
  right: 36px;
}

.header {
  background: var(--sl-primary);
  background-image: radial-gradient(circle at 0 100%, #00dfff 0%, #8c00ea 50%, #ff6200 100%);
  background-size: 200% 200%;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  max-width: 100%;
  padding: 24px 36px;
  display: flex;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --sl-primary: #00dfff;
  margin: 0;
  font-family: Space Mono, monospace;
  font-style: normal;
  font-weight: 400;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.space-mono-regular {
  font-family: Space Mono, monospace;
  font-style: normal;
  font-weight: 400;
}

.space-mono-bold {
  font-family: Space Mono, monospace;
  font-style: normal;
  font-weight: 700;
}

.space-mono-regular-italic {
  font-family: Space Mono, monospace;
  font-style: italic;
  font-weight: 400;
}

.space-mono-bold-italic {
  font-family: Space Mono, monospace;
  font-style: italic;
  font-weight: 700;
}
/*# sourceMappingURL=index.7f5726f2.css.map */
