
.prompt-dialog {
  background: rgba(255, 255, 255, 0.52);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.1px);
  -webkit-backdrop-filter: blur(7.1px);
  border-radius: 12px;
  right: 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 0px;
  width: 0px;
  bottom: 72px;
  position: absolute;
  z-index: 8;
  transition: 500ms;
  
  & > input {
    background-color: rgba(255, 255, 255, 0.65);
    border-radius: 4px;
    border: solid 1px darkgray;
    width: 100%;
    transition: opacity 100ms;
    line-height: 24px;
    opacity: 0;
  }

  &.open > input {
    opacity: 1;
  }

  &.open {
    height: 384px;
    width: 256px;
    opacity: 1;
    padding: 32px 16px 48px;
    transition-delay: 50ms;
  }

  & > h3 {
    margin: 0;
    opacity: 0;
    align-self: flex-start;
  }

  &.open > h3 {
    opacity: 1;
  }
}

.prompt-body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
  opacity: 0;
  max-height: 256px;
  overflow: auto;
  width: 100%;

  .open & {
    opacity: 1;
  }
}

.msg-entry {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 12px;
  width: 100%;
  
  & .icon {
    justify-self: center;
    margin-top: 6px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: rgba(14, 124, 255, 0.52);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.1px);
  }
}