a {
  color: #FAF9F6;
  text-decoration: none;
  transition: all ease-in-out 100ms;

  &:hover {
    color: #FAF9F6;
    transform: scale(1.05);
  }
}

.llamaHeader {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  position: absolute;
  right: 36px;
  top: 24px;
  width: 100%;
}

.header {
  background: var(--sl-primary);
  background-image: 
    radial-gradient(
      farthest-corner circle at 0% 100%, 
      #00dfff 0%, #8c00ea 50%, #ff6200 100%
    );
  background-size: 200% 200%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  padding: 24px 36px;
  max-width: 100%;
}