body {
  height: 100%;
  overflow-y: auto;
}

.resume {
  padding: 48px 64px;
}

.role {
  background-color: #f7f9fd;
  background: linear-gradient(#f7f9fd, rgba(0,0,0,0));
  border-radius: 16px;
  margin: 16px 0;
  padding: 16px 24px;
}
.skills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 12px;
  gap: 8px;
  margin-top: 16px;
}
.chip {
  background-color: #00dfff;
  border-radius: 24px;
  padding: 8px 12px;
  max-block-size: fit-content;
}